<template>
  <div>
    <b-card :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template #label>
                  <b>الطلبات: </b><br />
                  <b-form-checkbox
                    v-model="allSelected"
                    :indeterminate="indeterminate"
                    aria-describedby="options"
                    aria-controls="options"
                    class=""
                    @change="toggleAll"
                  >
                    {{ allSelected ? "ازل الكل" : "اختر الكل" }}
                  </b-form-checkbox>
                </template>

                <template v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="options"
                    v-model="data.orders"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="options"
                    class="ml-4 h1"
                    aria-label="Individual options"
                    stacked
                  ></b-form-checkbox-group>
                </template>
              </b-form-group>
              <infinite-loading @infinite="getOrders"></infinite-loading>
            </b-col>

            <b-col md="6">
              <b-form-group :label="$t('Add_Product.Note')" label-for="Note">
                <b-form-textarea id="Note" v-model="data.note" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                :disabled="dsiabledSubmit"
                @click.prevent="validationForm"
              >
                {{ $t("Add_Product.Submit") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
           <ul v-for="(values , index) in errors_back" :key="index">
                    <li v-for="(value , valIndex) in values" :key="valIndex">{{value}}</li>
                </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>

import { VueEditor } from "vue2-editor";
import { heightTransition } from "@core/mixins/ui/transition";
import { required } from "@validations";
export default {
  components: {

    VueEditor,
  },

  mixins: [heightTransition],
  watch: {},
  watch: {
    "data.orders": function(newValue, oldValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newValue.length === this.options.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
      if (this.data.orders.length > 0) {
        this.dsiabledSubmit = false;
      } else {
        this.dsiabledSubmit = true;
      }
    },
    //          selected(newValue, oldValue) {
    //     // Handle changes in individual flavour checkboxes
    //     if (newValue.length === 0) {
    //       this.indeterminate = false
    //       this.allSelected = false
    //     } else if (newValue.length === this.options.length) {
    //       this.indeterminate = false
    //       this.allSelected = true
    //     } else {
    //       this.indeterminate = true
    //       this.allSelected = false
    //     }

    //   }
  },
  data() {
    return {
      selected: [],
      allSelected: false,
      indeterminate: false,
      vendorOptions: [],
      dsiabledSubmit: true,
      currentPage: 0,
      vendorSelect: null,
      errors_back: [],
      available_amount: 0,
      showDismissibleAlert: false,
      required,
      data: {
        // vendor_id: "",
        // price: "",
        type: "manual",
        note: "",
        orders: [],
      },
      options: [],
    };
  },
  created() {
    // this.selectedVendor()
    // this.getOrders()
  },
  methods: {
    toggleAll(checked) {
      if (checked) {
        for (let index in this.options) {
          this.data.orders.push(this.options[index].value);
        }
      } else {
        this.data.orders = [];
      }
      // this.data.orders = checked ? this.options.slice() : []
    },
    // submit Brand API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axios
            .post("payouts/create", this.data)
            .then((result) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.back(1);
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
    // selectedVendor() {
    //     axios.get("payouts/available-amount/show").then((result) => {
    //         const data = result.data.data
    //         this.available_amount = data.available_amount
    //     }).catch((err) => {
    //         this.errors_back.length = 0
    //         if (err.response.data.data != null) {
    //             if (this.isString(err.response.data.data)) {
    //                 this.errors_back.push({
    //                     error: err.response.data.data
    //                 })
    //             } else {
    //                 this.errors_back = err.response.data.data
    //             }
    //             this.showDismissibleAlert = true
    //         } else {
    //             this.errors_back = []
    //             this.errors_back.push({
    //                 error: 'internal server error'
    //             })
    //             this.showDismissibleAlert = true
    //         }
    //     });
    // },
    getOrders($state) {
      this.currentPage += 1;
      axios
        .get("orders/payouts/available/50/sortBy-id-desc?page=" + this.currentPage)
        .then((result) => {
          const data = result.data.data.data;
          if (data.length > 0) {
            for (let index in data) {
              this.options.push({
                text:
                  "الكود: " +
                  data[index].code +
                  " " +
                  "- " +
                  " " +
                  "المبلغ: المستحق " +
                  data[index].payout_total +
                  " " +
                  "جنيه" +
                  " " +
                  "| التاريخ: " +
                  data[index].created_at,
                value: data[index].id,
              });
            }
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
    // basic
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped></style>

<style></style>
